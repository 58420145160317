import React from 'react';
import { Route } from 'react-router';
import Home from './Home';
import Login from './Login';
import Admin from './Admin';

export default (
  <Route path="/">
    {Home}
    <Route path="contact"><div></div></Route>
    <Route path="admin">{Admin}</Route>
    <Route path="login">{Login}</Route>
  </Route>
);
