import React from 'react';
import classes from './Progress.scss';

const Spinner = ({ percent, children }) => (
  <div className={classes['progress']}>
    <div className={`${classes['bar']} ${percent === 100 ? classes['done'] : ''}`} style={{width: percent + '%'}}>
      {children}
    </div>
  </div>
);

Spinner.propTypes = {
  percent: React.PropTypes.number.isRequired,
  children: React.PropTypes.node
};

export default Spinner;
