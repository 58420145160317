// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./handle.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._27CYQg9cqoZ_v1Qc0AVCEQ{display:-ms-flexbox;display:flex;-ms-flex:1 0 auto;flex:1 0 auto;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;margin:.5em 0;border-bottom:1px solid #888}._27CYQg9cqoZ_v1Qc0AVCEQ .Mwfk2xOuefQziLawv7psj{width:16px;height:16px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-position:50% 50%;background-repeat:no-repeat;margin-right:.5em;cursor:move}._27CYQg9cqoZ_v1Qc0AVCEQ input{color:#ccc;font-size:14px;background-color:#666;border:none;-ms-flex:1 0 auto;flex:1 0 auto}._27CYQg9cqoZ_v1Qc0AVCEQ input:not(:first-of-type):last-of-type{color:#ffb79c}._27CYQg9cqoZ_v1Qc0AVCEQ textarea{-ms-flex:1 0 auto;flex:1 0 auto}", "",{"version":3,"sources":["webpack://./src/routes/Admin/containers/MovieEditor/InputRow/InputRow.scss"],"names":[],"mappings":"AAAA,yBACE,mBAAA,CAAA,YAAA,CAAa,iBAAA,CACb,aAAA,CAAc,sBAAA,CACd,kBAAA,CAAmB,qBAAA,CACnB,kBAAA,CAAmB,aAAA,CACL,4BACe,CAN/B,gDASI,UAAA,CAAW,WAAA,CACC,wDAAA,CACmC,uBAAA,CACvB,2BAAA,CACI,2BAAA,CACA,iBAAA,CACV,WACN,CAhBhB,+BAoBI,UAAA,CAAW,cAAA,CACI,qBAAA,CACO,WAAA,CACV,iBAAA,CACZ,aAAc,CAxBlB,gEA4BI,aAAc,CA5BlB,kCAgCI,iBAAA,CAAA,aAAc","sourcesContent":[".input-row {\n  display: flex;\n  flex: 1 0 auto;\n  flex-direction: row;\n  align-items: center;\n  margin: .5em 0;\n  border-bottom: 1px solid #888;\n\n  .drag-hinge {\n    width: 16px;\n    height: 16px;\n    background-image: url('../InputRow/handle.svg');\n    background-size: contain;\n    background-position: 50% 50%;\n    background-repeat: no-repeat;\n    margin-right: .5em;\n    cursor: move;\n  }\n\n  input {\n    color: #ccc;\n    font-size: 14px;\n    background-color: #666;\n    border: none;\n    flex: 1 0 auto;\n  }\n\n  input:not(:first-of-type):last-of-type {\n    color: #ffb79c;\n  }\n\n  textarea {\n    flex: 1 0 auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-row": "_27CYQg9cqoZ_v1Qc0AVCEQ",
	"drag-hinge": "Mwfk2xOuefQziLawv7psj"
};
export default ___CSS_LOADER_EXPORT___;
