import React from 'react';
import shortid from 'shortid';
import { connect } from 'react-redux';
import ProductionList from '../../../../containers/ProductionList';
import Spinner from '../../../../components/Spinner';
import AdminMovieCard from '../../components/AdminMovieCard';
import * as productionService from '../../../../services/productionService';

class AdminProductionList extends React.Component {
  static propTypes = {
    token: React.PropTypes.string
  }

  static defaultProps = {
    token: null
  }

  state = {
    key: 'initial',
    spinnerActive: false
  }

  constructor (props) {
    super(props);
    this.onDeleteMovie = this.onDeleteMovie.bind(this);
  }

  async onDeleteMovie (id) {
    const proceed = confirm('Are you sure you want to delete this movie?');
    if (proceed) {
      this.setState({ spinnerActive: true });
      await productionService.deleteMovie(this.props.token, id);
      this.setState({ key: Math.random(), spinnerActive: false });
    }
  }

  render () {
    const newMovie = { id: shortid.generate(), isNewMovie: true };
    const gen = (production, images, lang) => [ newMovie, ...production ].map((movie, index) => {
      const isStock = movie.image && movie.image.startsWith('static_');
      let image;
      if (isStock) {
        image = movie.image;
      } else {
        image = images[ movie.image ] ? images[ movie.image ].thumb : '';
      }

      return <AdminMovieCard
        movie={movie}
        image={image}
        lang={lang}
        isNewMovie={movie.isNewMovie}
        onDelete={this.onDeleteMovie}
        key={index}
        stock={!!isStock} />;
    });

    return (
      <ProductionList key={this.state.key} cardGenerator={gen}>
        <Spinner active={this.state.spinnerActive} />
      </ProductionList>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token
});

export default connect(mapStateToProps)(AdminProductionList);
