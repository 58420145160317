import api from '../api';

export async function fetchCategories (lang, noun) {
  let categories = (await api.getCategories()).data;
  Object.keys(categories).forEach(key => {
    categories[ key ] = categories[ key ][ noun ][ lang ];
  });
  return categories;
}

export async function fetchCategory (key, lang, noun) {
  let categories = (await api.getCategories()).data;
  Object.keys(categories).forEach(key => {
    categories[key] = categories[ key ][ noun ][ lang ];
  });
  return categories[key];
}

export async function fetchCategoryImage (key) {
  let categories = (await api.getCategories()).data;
  return categories[key].image || '';
}
