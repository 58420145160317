import React from 'react';
import uuid from 'node-uuid';
import classes from './InputList.scss';
import InputRow from '../InputRow';
import AddButton from '../../../../../components/AddButton';
import Tooltip from '../../../../../components/Tooltip';

@Tooltip('Add Row')
class TooltippedAddButton extends React.Component {
  static propTypes = {
    onClick: React.PropTypes.func
  }

  render () {
    return <AddButton onClick={this.props.onClick}/>;
  }
}

export default class InputList extends React.Component {
  static propTypes = {
    name: React.PropTypes.string.isRequired,
    keys: React.PropTypes.array.isRequired,
    data: React.PropTypes.array.isRequired,
    onChange: React.PropTypes.func.isRequired
  };

  static defaultProps = {
    data: []
  };

  state = { data: [] };

  constructor (props) {
    super(props);

    this.swapData = this.swapData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addRow = this.addRow.bind(this);
  }

  componentWillReceiveProps (props) {
    this.setState({ data: this.addIDs(props.data) });
  }

  addIDs (data) {
    return data.map((item) => {
      return item.id ? item : Object.assign({}, item, { id: uuid.v4() });
    });
  }

  swapData (i1, i2) {
    let data = this.state.data.slice(0);
    let temp = data[ i1 ];
    data[ i1 ] = data[ i2 ];
    data[ i2 ] = temp;
    this.props.onChange(data);
  }

  deleteData (i) {
    let data = this.state.data.slice(0);
    if (data.length > 1) {
      data.splice(i, 1);
    } else {
      Object.keys(data[ 0 ]).filter(key => key !== 'id').forEach(key => {
        data[ 0 ][ key ] = '';
      });
      data = this.addIDs(data);
    }
    this.props.onChange(data);
  }

  addRow () {
    let data = this.state.data ? this.state.data.slice() : [];
    let item = {};
    this.props.keys.forEach(key => {
      item[ key ] = '';
    });
    item.id = uuid.v4();
    data.push(item);
    this.props.onChange(data);
  }

  handleChange (row, key, evt) {
    const value = evt.target.value;
    const data = this.state.data.slice(0);
    Object.assign(data[ row ], { [key]: value });
    this.props.onChange(data);
  }

  render () {
    const { name } = this.props;
    const { data } = this.state;

    return (
      <div className={classes['input-list']}>
        <div className={classes['title']}>
          <h1>{name}</h1>
          <TooltippedAddButton onClick={this.addRow} />
        </div>
        <div className={classes['rows']}>
          {data.map((item, index) => {
            return (
              <div key={item.id}>
                <InputRow
                  data={item}
                  name={name.toLowerCase()}
                  rowIndex={index}
                  key={item.id}
                  onSwap={this.swapData}
                  onDelete={this.deleteData}
                  onChange={this.handleChange} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
