import React from 'react';
import { GoogleMapLoader, GoogleMap, Marker } from 'react-google-maps';
import { triggerEvent } from 'react-google-maps/lib/utils';
import _ from 'lodash';
import markerIcon from './marker.svg';
import detectIE from 'detectie';

const mapStyle = [
  {
    'elementType': 'geometry',
    'stylers': [
      { 'hue': '#ff4400' },
      { 'saturation': -68 },
      { 'lightness': -4 },
      { 'gamma': 0.72 }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'labels.icon'
  },
  {
    'featureType': 'landscape.man_made',
    'elementType': 'geometry',
    'stylers': [
      { 'hue': '#0077ff' },
      { 'gamma': 3.1 }
    ]
  },
  {
    'featureType': 'water',
    'stylers': [
      { 'hue': '#00ccff' },
      { 'gamma': 0.44 },
      { 'saturation': -33 }
    ]
  },
  {
    'featureType': 'poi.park',
    'stylers': [
      { 'hue': '#44ff00' },
      { 'saturation': -23 }
    ]
  },
  {
    'featureType': 'water',
    'elementType': 'labels.text.fill',
    'stylers': [
      { 'hue': '#007fff' },
      { 'gamma': 0.77 },
      { 'saturation': 65 },
      { 'lightness': 99 }
    ]
  },
  {
    'featureType': 'water',
    'elementType': 'labels.text.stroke',
    'stylers': [
      { 'gamma': 0.11 },
      { 'weight': 5.6 },
      { 'saturation': 99 },
      { 'hue': '#0091ff' },
      { 'lightness': -86 }
    ]
  },
  {
    'featureType': 'transit.line',
    'elementType': 'geometry',
    'stylers': [
      { 'lightness': -48 },
      { 'hue': '#ff5e00' },
      { 'gamma': 1.2 },
      { 'saturation': -23 }
    ]
  },
  {
    'featureType': 'transit',
    'elementType': 'labels.text.stroke',
    'stylers': [
      { 'saturation': -64 },
      { 'hue': '#ff9100' },
      { 'lightness': 16 },
      { 'gamma': 0.47 },
      { 'weight': 2.7 }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];

const pos = { lat: 60.211331, lng: 25.049286 };

export default class GMap extends React.Component {
  state = {
    markers: [{
      position: pos,
      icon: detectIE()
        ? undefined
        : {
          url: markerIcon,
          scaledSize: new google.maps.Size(100, 100),
          size: new google.maps.Size(100, 100),
          anchor: new google.maps.Point(50, 100)
        },
      key: 'Pos',
      defaultAnimation: 2
    }]
  };

  constructor (props) {
    super(props);
    this.handleWindowResize = _.throttle(this.handleWindowResize.bind(this), 100);
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize () {
    triggerEvent(this.refs.googleMapComponent, 'resize');
    this.refs.googleMapComponent.props.map.setCenter(pos);
  }

  handleMarkerClick () {
    window.open(
      'https://www.google.com/maps/place/Pulttitie+16,+00880+Helsinki,+Suomi/@60.2113137,25.0470937,17z/data=!3m1!4b1!4m5!3m4!1s0x46920927d2490709:0x7bf796f7b8d18f05!8m2!3d60.2113111!4d25.0492824',
      '_blank'
    );
  }

  render () {
    return (
      <section style={{height: '100%'}}>
        <GoogleMapLoader
          containerElement={
            <div style={{height: '100%'}}></div>
          }
          googleMapElement={
            <GoogleMap
              ref="googleMapComponent"
              defaultZoom={15}
              defaultCenter={pos}
              defaultOptions={{
                styles: mapStyle,
                scrollwheel: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                fullscreenControl: false
              }}
            >
              {this.state.markers.map((marker, index) => {
                return <Marker {...marker} key={index} onClick={this.handleMarkerClick} />;
              })}
            </GoogleMap>
          }
        />
      </section>
    );
  }
}
