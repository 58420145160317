// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1rotkx-kEfDEooy3Z-EsXt{padding:.9em 1.2em;border-radius:3px;display:inline-block;text-align:center;font-size:13px;font-weight:600;background-color:#007ee5;background:linear-gradient(#007ee5,#0071ce);border:1px solid #0059b2;color:#fff;cursor:pointer}._1rotkx-kEfDEooy3Z-EsXt input[type=file]{display:none}._1rotkx-kEfDEooy3Z-EsXt:not(._3MDqE315P3DK-l8CkUvheJ):hover{background:#007ee5}._1rotkx-kEfDEooy3Z-EsXt._3MDqE315P3DK-l8CkUvheJ{cursor:default;color:#888;background:#eee;border:1px solid #ccc}._1rotkx-kEfDEooy3Z-EsXt._23JqUaOrAkxOSEvYcqlGb0{border:1px solid #ccc;color:#444;background:#fff}._1rotkx-kEfDEooy3Z-EsXt._23JqUaOrAkxOSEvYcqlGb0:hover{background:#fafafa}", "",{"version":3,"sources":["webpack://./src/components/Button/Button.scss"],"names":[],"mappings":"AAAA,yBACE,kBAAA,CAAmB,iBAAA,CACD,oBAAA,CACG,iBAAA,CACH,cAAA,CACH,eAAA,CACC,wBAAA,CACgB,2CAAA,CACa,wBAAA,CACZ,UAAA,CACtB,cACI,CAXjB,0CAcI,YAAa,CACd,6DAID,kBAAmB,CACpB,iDAGC,cAAA,CAAe,UAAA,CACJ,eAAA,CACK,qBACM,CACvB,iDAGC,qBAAA,CAAsB,UAAA,CACX,eACK,CACjB,uDAGC,kBAAmB","sourcesContent":[".button {\n  padding: .9em 1.2em;\n  border-radius: 3px;\n  display: inline-block;\n  text-align: center;\n  font-size: 13px;\n  font-weight: 600;\n  background-color: rgb(0,126,229);\n  background: linear-gradient(#007ee5, #0071ce);\n  border: 1px solid rgb(0, 89, 178);\n  color: #fff;\n  cursor: pointer;\n\n  input[type=\"file\"] {\n    display: none;\n  }\n}\n\n.button:not(.disabled):hover {\n  background: #007ee5;\n}\n\n.button.disabled {\n  cursor: default;\n  color: #888;\n  background: #eee;\n  border: 1px solid #ccc;\n}\n\n.button.white {\n  border: 1px solid #ccc;\n  color: #444;\n  background: #fff;\n}\n\n.button.white:hover {\n  background: #fafafa;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "_1rotkx-kEfDEooy3Z-EsXt",
	"disabled": "_3MDqE315P3DK-l8CkUvheJ",
	"white": "_23JqUaOrAkxOSEvYcqlGb0"
};
export default ___CSS_LOADER_EXPORT___;
