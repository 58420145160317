import React from 'react';
import classes from './AdminMovieCard.scss';
import MovieCard from '../../../../components/MovieCard';
import MovieThumbnail from '../../../../components/MovieThumbnail';
import { Link } from 'react-router';

class Overlay extends React.Component {
  static propTypes = {
    id: React.PropTypes.string.isRequired,
    onDelete: React.PropTypes.func
  };

  constructor (props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete () {
    this.props.onDelete(this.props.id);
  }

  render () {
    return (
      <div className={classes['overlay']}>
        <div className={classes['buttons']}>
          <Link to={`/admin/movies/${this.props.id}`}>Edit</Link>
          <a onClick={this.handleDelete}>Delete</a>
        </div>
      </div>
    );
  }
}

const OverlayNew = ({ id }) => (
  <div className={classes['overlay-new']}>
    <Link to={`/admin/movies/${id}`}>New Movie</Link>
  </div>
);

OverlayNew.propTypes = {
  id: React.PropTypes.string.isRequired
};

export default class AdminMovieCard extends React.Component {
  static propTypes = {
    onDelete: React.PropTypes.func.isRequired,
    movie: React.PropTypes.object.isRequired,
    image: React.PropTypes.string.isRequired,
    lang: React.PropTypes.string.isRequired,
    isNewMovie: React.PropTypes.bool,
    stock: React.PropTypes.bool.isRequired
  };

  constructor (props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete (movieId) {
    this.props.onDelete(movieId);
  }

  render () {
    const {movie, lang, isNewMovie, image, stock} = this.props;
    const thumbnail = <MovieThumbnail image={image} stock={stock} />;

    return (
      <MovieCard movie={movie} lang={lang} className="nogrow">
        {isNewMovie
          ? React.cloneElement(thumbnail, {}, <OverlayNew id={movie.id} />)
          : React.cloneElement(thumbnail, {}, <Overlay id={movie.id} onDelete={this.handleDelete} />)
        }
      </MovieCard>
    );
  }
};
