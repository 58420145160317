import React from 'react';
import App from '../../../components/App';
import Header from '../components/HomeHeader';
import Footer from '../../../components/Footer';

const HomeApp = ({ children }) => (
  <App>
    <Header />
    {children}
    <Footer />
  </App>
);

HomeApp.propTypes = {
  children: React.PropTypes.node
};

export default HomeApp;
