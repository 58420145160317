import React from 'react';
import classes from './Control.scss';

const Control = ({ onSubmit, onCancel }) => (
  <div className={classes['control']}>
    <ul>
      <li><a onClick={onSubmit}>Save</a></li>
      <li><a onClick={onCancel}>Cancel</a></li>
    </ul>
  </div>
);

Control.propTypes = {
  onSubmit: React.PropTypes.func.isRequired,
  onCancel: React.PropTypes.func.isRequired
};

export default Control;
