// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./rectangle.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ENQuiHMIJQB-rDh80w1er{padding:6px 0;width:100%;height:12px;vertical-align:bottom;background-color:rgba(0,0,0,.1);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:repeat-x;background-position:0 50%;background-origin:content-box}", "",{"version":3,"sources":["webpack://./src/components/Divider/Divider.scss"],"names":[],"mappings":"AAAA,yBACE,aAAA,CAAc,UAAA,CACH,WAAA,CACC,qBAAA,CACU,+BAAA,CACU,wDAAA,CACiB,uBAAA,CACzB,0BAAA,CACG,yBAAA,CACD,6BACI","sourcesContent":[".divider {\n  padding: 6px 0;\n  width: 100%;\n  height: 12px;\n  vertical-align: bottom;\n  background-color: rgba(0,0,0,.1);\n  background-image: url('../Divider/rectangle.svg');\n  background-size: contain;\n  background-repeat: repeat-x;\n  background-position: 0 50%;\n  background-origin: content-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "_1ENQuiHMIJQB-rDh80w1er"
};
export default ___CSS_LOADER_EXPORT___;
