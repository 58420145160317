import React from 'react';
import * as productionService from '../../../../services/productionService';
import * as imageService from '../../../../services/imageService';
import * as categoryService from '../../../../services/categoryService';
import classes from './Movie.scss';

export default class Contact extends React.Component {
  static propTypes = {
    params: React.PropTypes.object.isRequired,
    lang: React.PropTypes.string.isRequired
  };

  static defaultProps = {
    lang: 'eng'
  };

  state = {
    movie: {
      people: { cast: [], crew: [] }
    },
    image: { full: '' },
    category: ''
  };

  componentDidMount () {
    this.fetchMovieData();
  }

  async fetchMovieData () {
    const movie = await productionService.fetchMovie(this.props.params.movieKey);
    const image = await imageService.fetchImage(movie.image);
    const category = await categoryService.fetchCategory(movie.category, this.props.lang, 'singular');
    this.setState({ movie, image, category });
  }

  render () {
    const { title = { eng: '', fin: '' }, year, runtime, synopsis = '' } = this.state.movie;
    const titleMain = title[this.props.lang];
		const titleAlt = this.props.lang === 'eng' ? title['fin'] : title['eng'];

		const people = this.state.movie.people || { cast: [], crew: [] };

    return (
      <div className={classes['movie']}>
        <div className={`${classes['movie-content']} mid-cont flex-container dir-col`}>
          <div className={`${classes['content-top']} flex-container dir-row flex-wrap`}>
            {this.state.image.full &&
              <div className={classes['img-wrapper']}>
                <div
                  className={classes['img']}
                  style={{ backgroundImage: `url(/data/images/${this.state.image.full})` }}></div>
              </div>
            }
            <div className={`${classes['movie-info']} flex-item flex-container dir-col nowrap`}>
              <h1 className="wrap">{titleMain}</h1>
              <h2 className="wrap">{titleAlt}</h2>
              <div className="pad-2-v flex-container dir-col">
                <p>
                  <label>TYPE</label>
                  <span>{this.state.category}</span>
                </p>
                {year &&
                  <p>
                    <label>YEAR</label>
                    <span>{year}</span>
                  </p>
                }
                {runtime &&
                  <p>
                    <label>RUNTIME</label>
                    <span>{runtime}</span>
                  </p>
                }
              </div>
            </div>
          </div>
          <div className={`${classes['content-synopsis']} flex-container dir-row flex-wrap`}>
            <p dangerouslySetInnerHTML={{ __html: synopsis.replace(/(?:\r\n|\r|\n)/g, '<br />') }} />
          </div>
          <div className={`${classes['content-people']} flex-container dir-row flex-wrap`}>
            <div className="flex-container flex-item dir-col">
              <h1>Cast</h1>
              {(people.cast || []).map((person, index) => {
                return (
                  <p key={index}>
                    <span>{person.name}</span>
                  </p>
                );
              })}
            </div>
            <div className="flex-container flex-item dir-col">
              <h1>Crew</h1>
              {(people.crew || []).map((person, index) => {
                return (
                  <p key={index}>
                    <span>{person.name}</span>
                    <span className={classes['job']}>{person.job}</span>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
