import React from 'react';
import classes from './ImageBrowser.scss';
import * as imageService from '../../../../services/imageService';
import MovieThumbnail from '../../../../components/MovieThumbnail';
import elementQuery from '../../../../components/elementQuery';
import Uploader from '../../components/Uploader';
import Button from '../../../../components/Button';

class ImageThumbnail extends React.Component {
  static propTypes = {
    imageKey: React.PropTypes.string.isRequired,
    image: React.PropTypes.string.isRequired,
    onClick: React.PropTypes.func.isRequired
  }

  constructor (props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick () {
    this.props.onClick(this.props.imageKey);
  }

  render () {
    return (
      <div className={classes['thumbnail']} onClick={this.handleClick}>
        <MovieThumbnail image={this.props.image} />
      </div>
    );
  }
}

@elementQuery
class Images extends React.Component {
  static propTypes = {
    onSelect: React.PropTypes.func.isRequired,
    selectedImage: React.PropTypes.string.isRequired,
    images: React.PropTypes.object.isRequired
  };

  constructor (props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect (key) {
    this.props.onSelect(key);
  }

  render () {
    const { images, selectedImage } = this.props;

    return (
      <div className={`${classes['images']} flex-item`}>
        {Object.keys(images).map((key, i) => {
          const className = `${classes[ 'thumbnail-wrapper' ]} ${selectedImage === key ? classes[ 'selected' ] : ''}`;
          return (
            <div className={className} key={i}>
              <ImageThumbnail onClick={this.handleSelect} imageKey={key} image={images[key].thumb} />
            </div>
          );
        })}
      </div>
    );
  }
}

export default class ImageBrowser extends React.Component {
  static propTypes = {
    active: React.PropTypes.bool.isRequired,
    onDeactivate: React.PropTypes.func.isRequired,
    onSelect: React.PropTypes.func.isRequired,
    selectedImage: React.PropTypes.string.isRequired,
    token: React.PropTypes.string.isRequired
  };

  defaultProps = {
    onSelect: () => {},
    selectedImage: ''
  };

  state = {
    images: {},
    selectedImage: '',
    uploader: {
      active: false,
      fileName: '',
      progress: 0.0
    }
  };

  constructor (props) {
    super(props);
    this.fetchImages();

    this.handleClick = this.handleClick.bind(this);
    this.handleCompletedUpload = this.handleCompletedUpload.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleSelectImage = this.handleSelectImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentWillReceiveProps (props) {
    if (props.selectedImage && props.selectedImage !== this.state.selectedImage) {
      this.setState({ selectedImage: props.selectedImage });
    }
  }

  async fetchImages () {
    const images = await imageService.fetchImages();
    this.setState({ images });
  }

  handleClick (event) {
    /* deselect */
    if (this.refs.imgWrapper.contains(event.target) && !ancestorHasClass(event.target, classes[ 'thumbnail' ])) {
      this.setState({ selectedImage: '' });
    }

    /* deactivate (hide) imagebrowser */
    if (event.target.className.split(' ').indexOf(classes[ 'image-browser' ]) > -1) {
      this.props.onDeactivate();
    }
  }

  handleSelectImage (key) {
    this.setState({ selectedImage: key });
  }

  async handleUpload (evt) {
    let fd = new FormData();
    let files = this.refs.fileInput.files;
    fd.append('file', files[ 0 ], files[ 0 ].name);
    this.setState({
      uploader: Object.assign({}, this.state.uploader, {
        active: true,
        fileName: files[ 0 ].name
      })
    });

    let response = await imageService.uploadImage(this.props.token, fd, (evt) => {
      const progress = evt.loaded / evt.total;
      this.setState({
        uploader: Object.assign({}, this.state.uploader, { progress })
      });
    });

    console.log(response);
  }

  handleCompletedUpload () {
    this.setState({
      uploader: {
        active: false,
        fileName: '',
        progress: 0.0
      }
    });

    this.fetchImages();
  }

  async handleDelete () {
    let response = await imageService.deleteImage(this.props.token, this.state.selectedImage);
    this.setState({ images: response });
  }

  handleClickImage () {
    this.props.onSelect(this.state.selectedImage);
  }

  render () {
    const { images, selectedImage, uploader } = this.state;
    const { active, onDeactivate } = this.props;
    const style = active ? {} : { display: 'none' };

    //noinspection Eslint
    return (
      <div className={classes['image-browser']} style={style} onClick={this.handleClick}>
        <Uploader {...uploader} onComplete={this.handleCompletedUpload} />
        <div className={classes['image-browser-content']}>
          <div className={classes['header']}>
            <h1>Choose image</h1>
          </div>
          <div className={classes['images-wrapper']} ref="imgWrapper">
            <Images
              images={images}
              selectedImage={selectedImage}
              onSelect={this.handleSelectImage} />
          </div>
          <div className={classes['controls']}>
            <ul>
              <li>
                <Button className={classes['button']}>
                  <input type="file" ref="fileInput" accept=".jpg,.png" onChange={this.handleUpload} />
                  Upload Image...
                </Button>
              </li>
              <li>
                <Button className={classes['button']} white onClick={this.handleDelete}>
                  Delete Image
                </Button>
              </li>
            </ul>
            <ul>
              <li>
                <Button onClick={this.handleClickImage} className={classes['button']}>
                  Select Image
                </Button>
              </li>
              <li>
                <Button white onClick={onDeactivate} className={classes['button']}>Cancel</Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function ancestorHasClass (element, classname) {
  if (element.className && element.className.split(' ').indexOf(classname) > -1) {
    return true;
  }

  return element.parentNode && ancestorHasClass(element.parentNode, classname);
}
