// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._284r1wwxWZEJi8EYrZMNiw{box-sizing:border-box;position:relative;width:100%;background-color:#fff;text-align:left;height:32px;border:1px solid #b0b6c1}._284r1wwxWZEJi8EYrZMNiw .wnP6PbgzlTf3PU207vmA4{height:100%;top:0;left:0;background-color:#d7ebff}._284r1wwxWZEJi8EYrZMNiw .wnP6PbgzlTf3PU207vmA4,._284r1wwxWZEJi8EYrZMNiw .wnP6PbgzlTf3PU207vmA4 span{position:absolute;display:inline-block}._284r1wwxWZEJi8EYrZMNiw .wnP6PbgzlTf3PU207vmA4 span{color:#666;line-height:32px;margin-left:1em;font-size:13px}._284r1wwxWZEJi8EYrZMNiw .wnP6PbgzlTf3PU207vmA4._1gbRWUooJcrb83zlqFVj6c{background-color:#ddffd7}", "",{"version":3,"sources":["webpack://./src/components/Progress/Progress.scss"],"names":[],"mappings":"AAAA,yBACE,qBAAA,CAAsB,iBAAA,CACJ,UAAA,CACP,qBAAA,CACW,eAAA,CACN,WAAA,CACJ,wBACa,CAP3B,gDAWI,WAAA,CAAY,KAAA,CACN,MAAA,CACC,wBAEkB,CAf7B,qGAUI,iBAAA,CAAkB,oBAIG,CAdzB,qDAoBM,UAAA,CAAW,gBAAA,CACM,eAAA,CACD,cACD,CAvBrB,wEA4BI,wBAAyB","sourcesContent":[".progress {\n  box-sizing: border-box;\n  position: relative;\n  width: 100%;\n  background-color: #fff;\n  text-align: left;\n  height: 32px;\n  border: 1px solid #b0b6c1;\n\n  .bar {\n    position: absolute;\n    height: 100%;\n    top: 0;\n    left: 0;\n    display: inline-block;\n    background-color: #d7ebff;\n\n    span {\n      position: absolute;\n      display: inline-block;\n      color: #666;\n      line-height: 32px;\n      margin-left: 1em;\n      font-size: 13px;\n    }\n  }\n\n  .bar.done {\n    background-color: #ddffd7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": "_284r1wwxWZEJi8EYrZMNiw",
	"bar": "wnP6PbgzlTf3PU207vmA4",
	"done": "_1gbRWUooJcrb83zlqFVj6c"
};
export default ___CSS_LOADER_EXPORT___;
