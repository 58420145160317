import React from 'react';
import { findDOMNode } from 'react-dom';
import except from 'except';
import classes from './InputRow.scss';
import { DragSource, DropTarget } from 'react-dnd';
import DeleteButton from '../../../../../components/DeleteButton';
import Tooltip from '../../../../../components/Tooltip';

const rowSource = {
  beginDrag (props) {
    return {
      rowIndex: props.rowIndex,
      name: props.name
    };
  }
};

const listTarget = {
  hover (props, monitor, component) {
    const dragIndex = monitor.getItem().rowIndex;
    const hoverIndex = props.rowIndex;
    const dragName = monitor.getItem().name;
    const hoverName = props.name;

    if (dragIndex === hoverIndex || dragName !== hoverName) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.onSwap(dragIndex, hoverIndex);
    monitor.getItem().rowIndex = hoverIndex;
  }
};

function collectTarget (connect) {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

function collectSource (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

@Tooltip('Delete Row')
class TooltippedDeleteButton extends React.Component {
  static propTypes = {
    onClick: React.PropTypes.func
  }

  render () {
    return <DeleteButton onClick={this.props.onClick} />;
  }
}

class Input extends React.Component {
  static propTypes = {
    onChange: React.PropTypes.func.isRequired,
    inputKey: React.PropTypes.string.isRequired
  }

  constructor (props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange (evt) {
    this.props.onChange(this.props.inputKey, evt);
  }

  render () {
    const other = except(this.props, 'inputKey', 'onChange');
    return <input {...other} onChange={this.handleChange} />;
  }
}

@DropTarget('input_row', listTarget, collectTarget)
@DragSource('input_row', rowSource, collectSource)
export default class InputRow extends React.Component {
  static propTypes = {
    onChange: React.PropTypes.func,
    onDelete: React.PropTypes.func,
    name: React.PropTypes.string.isRequired,
    data: React.PropTypes.object.isRequired,
    onSwap: React.PropTypes.func.isRequired,
    connectDropTarget: React.PropTypes.func.isRequired,
    connectDragSource: React.PropTypes.func.isRequired,
    connectDragPreview: React.PropTypes.func.isRequired,
    isDragging: React.PropTypes.bool.isRequired,
    rowIndex: React.PropTypes.number.isRequired
  };

  constructor (props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChange (key, evt) {
    this.props.onChange(this.props.rowIndex, key, evt);
  }

  handleDelete () {
    this.props.onDelete(this.props.rowIndex);
  }

  render () {
    const { connectDragSource, connectDragPreview, connectDropTarget, isDragging, name, data, rowIndex } = this.props;

    return connectDropTarget(
      connectDragPreview(
        <div className={classes['input-row']} style={{opacity: isDragging ? 0 : 1}}>
          {connectDragSource(
            <div className={classes['drag-hinge']}></div>
          )}
          {Object.keys(data).map(key => {
            const placeholder = key.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase());
            return key === 'id' ||
              <Input
                type="text"
                name={`people[${name}][${rowIndex}][${key}]`}
                placeholder={placeholder}
                value={data[key]}
                key={[name, rowIndex, key].join('_')}
                inputKey={key}
                onChange={this.handleChange} />;
          })}
          <TooltippedDeleteButton onClick={this.handleDelete} />
        </div>
      )
    );
  }
}
