// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./plus.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3f3Bab_rVLS9UlzqcFKJTB{cursor:pointer;color:#ddd;padding:.5em 1em;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;font-size:13px;background-color:#447876}._3f3Bab_rVLS9UlzqcFKJTB:before{content:\" \";width:16px;height:16px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;background-position:50% 50%}._3f3Bab_rVLS9UlzqcFKJTB:hover{background-color:#4e9795}", "",{"version":3,"sources":["webpack://./src/components/AddButton/AddButton.scss"],"names":[],"mappings":"AAAA,yBACE,cAAA,CAAe,UAAA,CACJ,gBAAA,CACM,mBAAA,CACjB,YAAA,CAAa,qBAAA,CACb,kBAAA,CAAmB,cAAA,CACJ,wBACU,CAC1B,gCAGC,WAAA,CAAY,UAAA,CACD,WAAA,CACC,wDAAA,CACkC,2BAAA,CAClB,uBAAA,CACJ,2BACI,CAC7B,+BAGC,wBAAyB","sourcesContent":[".add-button {\n  cursor: pointer;\n  color: #ddd;\n  padding: .5em 1em;\n  display: flex;\n  align-items: center;\n  font-size: 13px;\n  background-color: #447876;\n}\n\n.add-button:before {\n  content: ' ';\n  width: 16px;\n  height: 16px;\n  background-image: url('../AddButton/plus.svg');\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: 50% 50%;\n}\n\n.add-button:hover {\n  background-color: #4e9795;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"add-button": "_3f3Bab_rVLS9UlzqcFKJTB"
};
export default ___CSS_LOADER_EXPORT___;
