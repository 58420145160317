// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1YSxewlsqMRzwINv7HFqFv ._1HAH7cquGoFLA7yynQ1F0_{position:relative;background-color:#444;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:stretch;align-items:stretch}._1YSxewlsqMRzwINv7HFqFv ._1HAH7cquGoFLA7yynQ1F0_ h1{color:#fff;padding:.5em}._1YSxewlsqMRzwINv7HFqFv .ilSJ5VfUkP66KEvHj7kd2{display:-ms-flexbox;display:flex;-ms-flex:1 0 auto;flex:1 0 auto;-ms-flex-direction:column;flex-direction:column;padding:1em}", "",{"version":3,"sources":["webpack://./src/routes/Admin/containers/MovieEditor/InputList/InputList.scss"],"names":[],"mappings":"AAAA,kDAEI,iBAAA,CAAkB,qBAAA,CACI,mBAAA,CACtB,YAAA,CAAa,sBAAA,CACb,kBAAA,CAAmB,qBAAA,CACnB,6BAAA,CAA8B,sBAAA,CAC9B,mBAAoB,CAPxB,qDAUM,UAAA,CAAW,YACE,CAXnB,gDAgBI,mBAAA,CAAA,YAAA,CAAa,iBAAA,CACb,aAAA,CAAc,yBAAA,CACd,qBAAA,CAAsB,WACV","sourcesContent":[".input-list {\n  .title {\n    position: relative;\n    background-color: #444;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: stretch;\n\n    h1 {\n      color: #fff;\n      padding: .5em;\n    }\n  }\n\n  .rows {\n    display: flex;\n    flex: 1 0 auto;\n    flex-direction: column;\n    padding: 1em;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-list": "_1YSxewlsqMRzwINv7HFqFv",
	"title": "_1HAH7cquGoFLA7yynQ1F0_",
	"rows": "ilSJ5VfUkP66KEvHj7kd2"
};
export default ___CSS_LOADER_EXPORT___;
