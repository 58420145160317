import React from 'react';
import { connect } from 'react-redux';
import * as productionService from '../../../../services/productionService';
import * as imageService from '../../../../services/imageService';
import * as categoryService from '../../../../services/categoryService';
import classes from './MovieEditor.scss';
import EditorSection from './EditorSection';
import InputList from './InputList';
import Control from './Control';
import TextEditorSection from './TextEditorSection';
import TextAreaEditorSection from './TextAreaEditorSection';
import SelectEditorSection from './SelectEditorSection';
import ImageBrowser from '../ImageBrowser';
import MovieThumbnail from '../../../../components/MovieThumbnail';
import Spinner from '../../../../components/Spinner';

class MovieEditor extends React.Component {
  static propTypes = {
    params: React.PropTypes.object,
    token: React.PropTypes.string
  };

  state = {
    movieData: {
      title: { fin: '', eng: '' },
      category: '',
      director: '',
      year: 0,
      runtime: '',
      synopsis: '',
      image: '',
      people: { cast: [], crew: [] }
    },
    categories: {},
    imageBrowser: false,
    spinner: true,
    imageData: { full: '', thumb: '' },
    lang: { title: 'eng' }
  };

  constructor (props) {
    super(props);

    this.fetchMovie(props.params.movieKey);
    this.fetchCategories();

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSelectImage = this.onSelectImage.bind(this);
    this.activateImageBrowser = this.activateImageBrowser.bind(this);
    this.deactivateImageBrowser = this.deactivateImageBrowser.bind(this);

    this.changeHandlers = [ 'title', 'category', 'year', 'runtime', 'synopsis' ].reduce((pre, key) => {
      pre.input[key] = this.handleEditorSectionInputChange.bind(this, key);
      pre.lang[key] = this.handleEditorSectionLangChange.bind(this, key);
      return pre;
    }, { input: {}, lang: {}, people: {} });
    this.changeHandlers.people['cast'] = this.handlePeopleChange.bind(this, 'cast');
    this.changeHandlers.people['crew'] = this.handlePeopleChange.bind(this, 'crew');
  }

  componentDidMount () {
    this.setState({ spinner: false });
  }

  async fetchMovie (key) {
    const movieData = await productionService.fetchMovie(key) || this.state.movieData;
    const imageData = await imageService.fetchImage(movieData.image);
    this.setState({ movieData, imageData });
  }

  async fetchCategories () {
    const categories = await categoryService.fetchCategories('eng', 'singular');
    this.setState({ categories });
  }

  async onSubmit () {
    this.setState({ spinner: true });
    let fd = new FormData(this.refs.form);
    let movieData = await productionService.submitMovie(this.props.token, fd);
    this.setState({ movieData, spinner: false });
  }

  onCancel () {
    this.fetchMovie(this.props.params.movieKey);
  }

  async onSelectImage (image) {
    if (image) {
      const movieData = Object.assign({}, this.state.movieData, { image });
      const imageData = await imageService.fetchImage(image);
      this.setState({ movieData, imageData });
    } else {
      const categoryImage = await categoryService.fetchCategoryImage(this.state.movieData.category);
      const movieData = Object.assign({}, this.state.movieData, { image: categoryImage });
      const imageData = { full: '', thumb: categoryImage };
      this.setState({ movieData, imageData });
    }

    this.deactivateImageBrowser();
  }

  activateImageBrowser () {
    this.setState({ imageBrowser: true });
  }

  deactivateImageBrowser () {
    this.setState({ imageBrowser: false });
  }

  handleEditorSectionLangChange (section, lang) {
    this.setState({ lang: Object.assign({}, this.state.lang, { [section]: lang }) });
  }

  handleEditorSectionInputChange (key, value) {
    this.setState({ movieData: Object.assign({}, this.state.movieData, { [key]: value }) });
  }

  handlePeopleChange (people, data) {
    this.setState({
      movieData: Object.assign({}, this.state.movieData, {
        people: Object.assign({}, this.state.movieData.people, { [people]: data })
      })
    });
  }

  render () {
    const { title, year, category, runtime, synopsis, image } = this.state.movieData;
    const { imageData, spinner, lang } = this.state;
    const people = this.state.movieData.people || { cast: [], crew: [] };

    return (
      <div className={classes['movie-editor']}>
        <Spinner active={spinner} />
        <ImageBrowser
          active={this.state.imageBrowser}
          onSelect={this.onSelectImage}
          onDeactivate={this.deactivateImageBrowser}
          selectedImage={image}
          token={this.props.token} />
        <form ref="form" className="flex-item">
          <input type="hidden" name="_key" value={this.props.params.movieKey} />
          <input type="hidden" name="image" value={image} />
          <div className={classes['image-div']}>
            <div className={classes['image-wrapper']} onClick={this.activateImageBrowser}>
              <MovieThumbnail image={imageData.thumb} stock={!!imageData.thumb && !imageData.full} />
              <div className={classes['overlay']}>
                Choose Image
              </div>
            </div>
          </div>
          <div className={classes['form-input']}>
            <div className={classes['col']}>
              <EditorSection
                title="Title"
                value={{...title}}
                selectedLang={lang.title}
                onChangeLang={this.changeHandlers['lang']['title']}
                onChange={this.changeHandlers['input']['title']}
                type="text">
                <TextEditorSection />
              </EditorSection>
              <EditorSection
                title="Category"
                value={category}
                onChange={this.changeHandlers['input']['category']}
                type="select">
                <SelectEditorSection choices={this.state.categories} />
              </EditorSection>
              <EditorSection
                title="Year"
                value={year}
                onChange={this.changeHandlers['input']['year']}
                type="text">
                <TextEditorSection />
              </EditorSection>
              <EditorSection
                title="Runtime"
                value={runtime}
                onChange={this.changeHandlers['input']['runtime']}
                type="text">
                <TextEditorSection />
              </EditorSection>
              <EditorSection
                title="Synopsis"
                value={synopsis}
                onChange={this.changeHandlers['input']['synopsis']}
                type="textarea">
                <TextAreaEditorSection />
              </EditorSection>
            </div>
            <div className={classes['col']}>
              <div className={classes['people']}>
                <div className={classes['cast']}>
                  <InputList
                    key="cast"
                    name="Cast"
                    data={people.cast}
                    keys={['name']}
                    onChange={this.changeHandlers['people']['cast']} />
                </div>
                <div className={classes['crew']}>
                  <InputList
                    key="crew"
                    name="Crew"
                    data={people.crew}
                    keys={['name', 'job']}
                    onChange={this.changeHandlers['people']['crew']} />
                </div>
              </div>
            </div>
          </div>
          <Control onSubmit={this.onSubmit} onCancel={this.onCancel} />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token
});

export default connect(mapStateToProps)(MovieEditor);
