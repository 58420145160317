// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./logo2.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "./logo-text2.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2Q03alddOcgXIwX72XY83Y{padding:16px 0;display:block;white-space:nowrap}._2Q03alddOcgXIwX72XY83Y ._16u6N9j76P1oq2Zs-gthQK,._2Q03alddOcgXIwX72XY83Y ._1OXNTmiDzRL82WZEk2sIsz{display:inline-block;height:70px;background-size:contain;background-repeat:no-repeat;background-position:0 50%}._2Q03alddOcgXIwX72XY83Y ._16u6N9j76P1oq2Zs-gthQK{width:70px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}._2Q03alddOcgXIwX72XY83Y ._1OXNTmiDzRL82WZEk2sIsz{width:300px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@media (max-width:460px){._2Q03alddOcgXIwX72XY83Y ._16u6N9j76P1oq2Zs-gthQK{display:none}._2Q03alddOcgXIwX72XY83Y ._1OXNTmiDzRL82WZEk2sIsz{width:100%}}", "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.scss"],"names":[],"mappings":"AAAA,yBACE,cAAA,CAAe,aAAA,CACD,kBACK,CAHrB,oGAMI,oBAAA,CAAqB,WAAA,CACT,uBAAA,CACY,2BAAA,CACI,yBACF,CAV9B,kDAcI,UAAA,CAAW,wDAC+B,CAf9C,kDAmBI,WAAA,CAAY,wDACmC,CAChD,yBArBH,kDAyBM,YAAa,CAzBnB,kDA6BM,UAAW,CACZ","sourcesContent":[".logo {\n  padding: 16px 0;\n  display: block;\n  white-space: nowrap;\n\n  .logo-image, .logo-text {\n    display: inline-block;\n    height: 70px;\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: 0 50%;\n  }\n\n  .logo-image {\n    width: 70px;\n    background-image: url('../Logo/logo2.svg');\n  }\n\n  .logo-text {\n    width: 300px;\n    background-image: url('../Logo/logo-text2.svg');\n  }\n\n  @media (max-width: 460px) {\n    .logo-image {\n      display: none;\n    }\n\n    .logo-text {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "_2Q03alddOcgXIwX72XY83Y",
	"logo-image": "_16u6N9j76P1oq2Zs-gthQK",
	"logo-text": "_1OXNTmiDzRL82WZEk2sIsz"
};
export default ___CSS_LOADER_EXPORT___;
