import React from 'react';
import { connect } from 'react-redux';
import { push } from 'redux-router';
import Spinner from '../components/Spinner';

export function requireAuthentication (Component) {
  class AuthenticatedApp extends React.Component {
    static propTypes = {
      isAuthenticated: React.PropTypes.bool.isRequired,
      isAuthenticating: React.PropTypes.bool.isRequired,
      location: React.PropTypes.object.isRequired,
      dispatch: React.PropTypes.func.isRequired
    };

    componentWillMount () {
      this.checkAuth(this.props.isAuthenticated);
    }

    componentWillReceiveProps (nextProps) {
      this.checkAuth(nextProps.isAuthenticated);
    }

    checkAuth (isAuthenticated) {
      if (!isAuthenticated) {
        let redirectAfterLogin = this.props.location.pathname;
        if (redirectAfterLogin === '/handleLogin') {
          redirectAfterLogin = '/admin';
        }

        this.props.dispatch(push(`/login?next=${redirectAfterLogin}`));
      }
    }

    render () {
      return this.props.isAuthenticated
        ? <Component {...this.props} />
        : <Spinner active />;
    }
  }

  const mapStateToProps = (state) => ({
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating
  });

  return connect(mapStateToProps)(AuthenticatedApp);
}
