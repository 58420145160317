import React from 'react';
import { Route, IndexRoute } from 'react-router';
import HomeApp from './containers/HomeApp';
import ProductionList from '../../containers/ProductionList';
import Contact from './containers/Contact';
import Movie from './containers/Movie';

export default (
  <Route component={HomeApp}>
    <IndexRoute component={ProductionList} />
    <Route path="contact" component={Contact} />
    <Route path="movies/:movieKey" component={Movie} />
  </Route>
);
