import api from '../api';

export async function fetchProduction (lang) {
  const production = (await api.getProduction()).data;
  const categories = (await api.getCategories()).data;

  let data = Object.keys(production).map(key => {
    const movie = production[ key ];
    const catId = movie.category;
    const category = categories[ catId ][ 'singular' ][ lang ];
    return Object.assign({}, movie, { category, categoryId: catId, id: key });
  });

  data.sort((a, b) => {
		let yearA = a.year.match(/\d+/);
		yearA = yearA ? parseInt(yearA[0], 10) : 0;
    let yearB = b.year.match(/\d+/);
		yearB = yearB ? parseInt(yearB[0], 10) : 0;

    if (yearA === yearB) {
      return a.title.eng.replace(/^The /i, '').localeCompare(b.title.eng.replace(/^The /i, ''));
    }

    if (yearA < 50) yearA += 2000;
		else if (yearA < 100) yearA += 1900;

    if (yearB < 50) yearB += 2000;
		else if (yearB < 100) yearB += 1900;

    return yearB - yearA;
  });

  return data;
}

export async function fetchMovie (key) {
  const production = (await api.getProduction()).data;
  return production[ key ];
}

export async function submitMovie (token, fd) {
  let response = (await api.submitMovie(token, fd)).data;
  return response;
}

export async function deleteMovie (token, id) {
  let fd = new FormData();
  fd.append('type', 'movie');
  fd.append('id', id);
  let response = (await api.deleteMovie(token, fd)).data;
  return response;
}
