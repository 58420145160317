import React from 'react';
import classes from './Tooltip.scss';

export default (text) => {
  return Component => class extends Component {
    constructor (props) {
      super(props);
      this.setTooltipActive = this.setTooltipActive.bind(this);
      this.state = { active: false };
    }

    setTooltipActive (active) {
      this.setState({ active });
    }

    render () {
      return React.cloneElement(
        super.render(),
        {
          style: { position: 'relative' },
          onMouseEnter: () => this.setTooltipActive(true),
          onMouseLeave: () => this.setTooltipActive(false)
        },
        <Tooltip active={this.state.active} text={text} />
      );
    }
  };
};

class Tooltip extends React.Component {
  static propTypes = {
    active: React.PropTypes.bool,
    text: React.PropTypes.string.isRequired
  };

  static defaultProps = {
    active: false
  };

  state = {
    top: 0,
    left: 0
  };

  componentDidUpdate () {
    const tt = this.refs.tooltip;
    const rect = tt.getBoundingClientRect();
    const prect = tt.parentElement.getBoundingClientRect();
    const top = -rect.height - 14;
    const left = (prect.width - rect.width) / 2;

    if (top !== this.state.top || left !== this.state.left) {
      this.setState({ top, left });
    }
  }

  render () {
    const { left, top } = this.state;
    let style = {
      left: left + 'px',
      top: top + 'px'
    };

    if (!this.props.active) {
      style.display = 'none';
    }

    return (
      <div className={classes['tooltip']} ref="tooltip" style={style}>
        <span>{this.props.text}</span>
        <div className={classes['arrow']}></div>
      </div>
    );
  }
}
