import React from 'react';
import { IndexLink } from 'react-router';
import Header from '../../../components/Header';

const AdminHeader = ({ onLogout }) => (
  <Header>
    <IndexLink to="/admin">PRODUCTION</IndexLink>
    <a onClick={onLogout}>LOGOUT</a>
  </Header>
);

AdminHeader.propTypes = {
  onLogout: React.PropTypes.func.isRequired
};

export default AdminHeader;
