import React from 'react';
import classes from './Button.scss';

const Button = ({children, disabled, onClick, white, className, ...other}) => {
  let cn = classes[ 'button' ] + ' ' + (className || '');
  if (white) cn += ' ' + classes[ 'white' ];
  if (disabled) cn += ' ' + classes[ 'disabled' ];

  return (
    <label className={cn} {...other} onClick={disabled ? () => {} : onClick}>
      {children}
    </label>
  );
};

const {node, bool, func, string} = React.PropTypes;
Button.propTypes = {
  children: node,
  disabled: bool,
  onClick: func,
  white: bool,
  className: string
};

export default Button;
