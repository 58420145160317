import axios from 'axios';

export default {
  getProduction: () => {
    return axios.get('/data/production.json');
  },
  getCategories: () => {
    return axios.get('/data/categories.json');
  },
  getImages: () => {
    return axios.get('/data/images.json');
  },
  submitMovie: (token, fd) => {
    return axios.post('/api/edit', fd, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
  },
  uploadImage: (token, fd, onProgress) => {
    return axios.post('/api/upload', fd, {
      headers: { 'Authorization': `Bearer ${token}` },
      progress: onProgress
    });
  },
  deleteMovie: (token, fd) => {
    return axios.post('/api/delete', fd, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
  },
  deleteImage: (token, fd) => {
    return axios.post('/api/deleteImage', fd, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
  }
};
