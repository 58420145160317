import React from 'react';

const SelectEditorSection = ({ choices, ...other }) => (
  <select {...other}>
    {Object.keys(choices).map((key, index) => {
      return <option key={index} value={key}>{choices[ key ]}</option>;
    })}
  </select>
);

SelectEditorSection.propTypes = {
  choices: React.PropTypes.object.isRequired
};

export default SelectEditorSection;
