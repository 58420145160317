require('./polyfills');

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReduxRouter, reduxReactRouter } from 'redux-router';
import { createHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { authenticateToken, loginUserSuccess } from './actions';
import routes from './routes';

require('./styles/style.scss');

const MOUNT_NODE = document.getElementById('content');
const middleware = applyMiddleware(thunk);
const createStoreWithMiddleware = compose(
  middleware,
  reduxReactRouter({ routes, createHistory })
);

const store = createStoreWithMiddleware(createStore)(reducers, {});

/* jwt token loading */
const token = localStorage.getItem('token');
if (token !== null) {
  /* assume any existing token is valid, but check it immediately after */
  store.dispatch(loginUserSuccess(token));
  store.dispatch(authenticateToken(token));
}

/* hot module loading */
if (__DEV__ && module.hot) {
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers/index');
    store.replaceReducer(nextRootReducer);
  });
}

try {
  ReactDOM.render(
    <Provider store={store}>
      <ReduxRouter>
        {routes}
      </ReduxRouter>
    </Provider>,
    MOUNT_NODE
  );
} catch (err) {
  console.error(err.stack);
}
