import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Header from '../components/AdminHeader';
import App from '../../../components/App';
import * as actionCreators from '../../../actions';

const AdminApp = ({ isAuthenticated, actions, children }) => (
  <App>
    {isAuthenticated && <Header onLogout={actions.logoutAndRedirect} />}
    {children}
  </App>
);

AdminApp.propTypes = {
  isAuthenticated: React.PropTypes.bool.isRequired,
  actions: React.PropTypes.object.isRequired,
  children: React.PropTypes.node.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch)
});

export default DragDropContext(HTML5Backend)(
  connect(mapStateToProps, mapDispatchToProps)(AdminApp)
);
