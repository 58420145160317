import React from 'react';
import classes from './MovieThumbnail.scss';

const MovieThumbnail = ({ image, stock, ...other }) => {
  const thumbStyle = { backgroundImage: image ? `url(/data/images/${image})` : 'none' };
  return (
    <div
      className={`${classes['movie-thumbnail']} ${stock && classes['stock']}`}
      style={thumbStyle}
      {...other}
    >
    </div>
  );
};

MovieThumbnail.propTypes = {
  image: React.PropTypes.string.isRequired,
  stock: React.PropTypes.bool.isRequired
};

MovieThumbnail.defaultProps = {
  stock: false
};

export default MovieThumbnail;
