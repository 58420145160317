import React from 'react';
import classes from './Footer.scss';

const Footer = () => (
  <div className={`${classes['footer']} cont`}>
    <div className="mid-cont flex-container flex-center dir-row">
      <div className="flex-container flex-vtop">
        <p>Copyright © {(new Date()).getFullYear()} Silva Mysterium Oy</p>
      </div>
    </div>
  </div>
);

export default Footer;
