import React from 'react';
import classes from './TopNav.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const externalIcon = <FontAwesomeIcon icon={faExternalLinkAlt} />

const TopNav = ({ children }) => (
  <div className={classes['top-nav']}>
    <ul>
      {React.Children.map(children, child => {
        return (child.type.displayName === 'Link' || child.type.displayName === 'IndexLink')
          ? <li>{React.cloneElement(child, { activeClassName: classes[ 'active' ] })}</li>
          : <li>{child}</li>;
      })}
      <li className={classes['flexer']}/>
      <li className={classes['merch-link']}>
        <div>
          <a target="_blank" href="https://www.silvamysteriumclothing.fi">SILVAMYSTERIUM CLOTHING{externalIcon}</a>
        </div>
        </li>
    </ul>
  </div>
);

TopNav.propTypes = {
  children: React.PropTypes.node
};

export default TopNav;
