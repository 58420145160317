require('es6-symbol/implement');
require('es6-promise').polyfill();
require('event-source-polyfill');

/* Object.startsWith polyfill */
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}
