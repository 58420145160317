import React from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'redux-router';
import { connect } from 'react-redux';
import classes from './LoginApp.scss';
import * as actionCreators from '../../../../actions';
import Spinner from '../../../../components/Spinner';

class Login extends React.Component {
  static propTypes = {
    location: React.PropTypes.object.isRequired,
    dispatch: React.PropTypes.func.isRequired,
    actions: React.PropTypes.object.isRequired,
    isAuthenticated: React.PropTypes.bool.isRequired,
    isAuthenticating: React.PropTypes.bool.isRequired
  };

  constructor (props) {
    super(props);
    const redirectRoute = this.props.location.query.next || '/admin';

    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      username: '',
      password: '',
      redirectTo: redirectRoute
    };
  }

  componentWillMount () {
    this.checkAuth(this.props.isAuthenticated);
  }

  componentWillReceiveProps (nextProps) {
    this.checkAuth(nextProps.isAuthenticated);
  }

  checkAuth (isAuthenticated) {
    if (isAuthenticated) {
      this.props.dispatch(push('/admin'));
    }
  }

  handleLogin () {
    let fd = new FormData();
    fd.append('username', this.state.username);
    fd.append('password', this.state.password);
    this.props.actions.loginUser(fd, this.state.redirectTo);
  }

  handleChange (e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render () {
    return (
      <div className={classes['login']}>
        <Spinner active={this.props.isAuthenticating} />
        <div className={classes['login-form']}>
          <form>
            <div className={classes['form-group']}>
              <span className={classes['placeholder']}>USERNAME</span>
              <input
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.handleChange}
                ref="username" />
            </div>
            <div className={classes['form-group']}>
              <span className={classes['placeholder']}>PASSWORD</span>
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                ref="password" />
            </div>
            <div className={classes['form-group']}>
              <a className={classes['button']} onClick={this.handleLogin}>Log in</a>
            </div>
          </form>
          <p>
            Came here by accident? <a href="/">Go back</a>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticating: state.auth.isAuthenticating,
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
