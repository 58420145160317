import React from 'react';
import Progress from '../../../../components/Progress';
import Button from '../../../../components/Button';
import classes from './Uploader.scss';

const MovieRow = ({ fileName, progress, onComplete, active }) => {
  const percent = Math.floor(progress * 100);

  return (
    <div className={classes['uploader']} style={active ? {} : {display: 'none'}}>
      <div className={classes['uploader-content']}>
        <div className={classes['header']}>
          <h1>Upload image</h1>
        </div>
        <div className={`${classes['content']} flex-item`}>
          <Progress percent={percent}>
            <span>{fileName}</span>
          </Progress>
          <div className={classes['control']}>
            <Button onClick={onComplete} disabled={percent < 100}>Done</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

MovieRow.propTypes = {
  fileName: React.PropTypes.string.isRequired,
  progress: React.PropTypes.number.isRequired,
  onComplete: React.PropTypes.func.isRequired,
  active: React.PropTypes.bool.isRequired
};

export default MovieRow;
