// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1MPNQtioFOxL7bkKtuuEdf{position:relative;display:block;box-sizing:border-box;width:100%;padding-bottom:56.25%;background-position:50% 50%;background-size:contain;background-color:#222;background-repeat:no-repeat;box-shadow:0 0 10px 0 rgba(0,0,0,.3)}._1MPNQtioFOxL7bkKtuuEdf.wU60OSbur1EosEnHR4Bz1{background-size:25%}", "",{"version":3,"sources":["webpack://./src/components/MovieThumbnail/MovieThumbnail.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CAAkB,aAAA,CACJ,qBAAA,CACQ,UAAA,CACX,qBAAA,CACW,2BAAA,CACM,uBAAA,CACJ,qBAAA,CACF,2BAAA,CACM,oCACY,CACzC,+CAGC,mBAAoB","sourcesContent":[".movie-thumbnail {\n  position: relative;\n  display: block;\n  box-sizing: border-box;\n  width: 100%;\n  padding-bottom: 56.25%;\n  background-position: 50% 50%;\n  background-size: contain;\n  background-color: #222;\n  background-repeat: no-repeat;\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .3);\n}\n\n.movie-thumbnail.stock {\n  background-size: 25%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"movie-thumbnail": "_1MPNQtioFOxL7bkKtuuEdf",
	"stock": "wU60OSbur1EosEnHR4Bz1"
};
export default ___CSS_LOADER_EXPORT___;
