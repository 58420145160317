import React from 'react';
import classes from './EditorSection.scss';

export default class EditorSection extends React.Component {
  static propTypes = {
    onChange: React.PropTypes.func,
    onChangeLang: React.PropTypes.func,
    title: React.PropTypes.string.isRequired,
    type: React.PropTypes.string,
    value: React.PropTypes.oneOfType([
      React.PropTypes.string,
      React.PropTypes.number,
      React.PropTypes.object
    ]),
    choices: React.PropTypes.object,
    selectedLang: React.PropTypes.string,
    children: React.PropTypes.node
  };

  static defaultProps = {
    type: 'text',
    selectedLang: 'eng',
    onChangeLang: () => {}
  };

  constructor (props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.changeLangHandlers = ['fin', 'eng'].reduce((pre, key) => {
      pre[key] = this.handleChangeLang.bind(this, key);
      return pre;
    }, {});
  }

  isActive (lang) {
    return lang === this.props.selectedLang;
  }

  handleChange (event) {
    const { selectedLang, onChange } = this.props;
    const value = this.props.value || '';
    const isBilingual = value.hasOwnProperty('eng');
    const newVal = event.target.value;
    const obj = {
      eng: selectedLang === 'eng' ? newVal : value.eng,
      fin: selectedLang === 'fin' ? newVal : value.fin
    };

    onChange(isBilingual ? obj : obj.eng);
  }

  handleChangeLang (lang) {
    this.props.onChangeLang(lang);
  }

  render () {
    const { title, type, value, selectedLang } = this.props;
    const isBilingual = this.props.value && this.props.value.hasOwnProperty('eng');
    const titleLower = title.toLowerCase();

    const name = isBilingual
      ? `${titleLower}[${isBilingual ? selectedLang : 'eng'}]`
      : titleLower;

    const nameAlt = `${titleLower}[${selectedLang === 'eng' ? 'fin' : 'eng'}]`;

    return (
      <div className={`${classes['editor-section']} ${classes[type] || ''}`}>
        <div className={classes['title']}>
          <h1>{title}</h1>
          {isBilingual &&
            <div className={classes['language-buttons']}>
              <a onClick={this.changeLangHandlers['eng']} className={this.isActive('eng') && classes['active']}>eng</a>
              <a onClick={this.changeLangHandlers['fin']} className={this.isActive('fin') && classes['active']}>fin</a>
            </div>
          }
        </div>
        {isBilingual &&
          <input type="hidden" name={nameAlt} value={selectedLang === 'eng' ? value.fin : value.eng} />
        }
        {React.cloneElement(this.props.children, {
          onChange: this.handleChange,
          name,
          value: isBilingual ? value[ selectedLang ] : value
        })}
      </div>
    );
  }
}
