import React from 'react';
import classes from './MovieCard.scss';

export default class MovieCard extends React.Component {
  static propTypes = {
    movie: React.PropTypes.object.isRequired,
    lang: React.PropTypes.string.isRequired,
    className: React.PropTypes.string,
    children: React.PropTypes.node,
    onClick: React.PropTypes.func.isRequired
  };

  static defaultProps = {
    onClick: () => {}
  };

  constructor (props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick () {
    this.props.onClick(this.props.movie.id);
  }

  render () {
    const { movie, lang, children, className = '' } = this.props;
    const langAlt = lang === 'eng' ? 'fin' : 'eng';
    const { year, category } = movie;
    const title = movie.title || { eng: '', fin: '' };
    const titleMain = (title[ lang ] ? title[ lang ] : title[ langAlt ]);
    const titleAlt = (title[ lang ] ? title[ langAlt ] : '');
    const cName = `${classes[ 'movie-card-wrapper' ]} grow ${className}`;

    return (
      <div className={cName} onClick={this.handleClick}>
        <div className={classes['movie-card']}>
          {children}
          <div className={classes['movie-card-info']}>
            <div className={classes['title']}>{titleMain}</div>
            <div>{titleAlt}</div>
            <div>
              <span className={classes['year']}>{year}</span>
              <span>{category}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
