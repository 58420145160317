// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZvXK93SsvxyIb233FSywy{position:absolute;display:inline-block;white-space:nowrap;padding:.5em;background-color:rgba(0,0,0,.7);border-radius:4px;font-size:12px}.ZvXK93SsvxyIb233FSywy .beEjLFtCH_zRVDXayXNv0{position:absolute;width:0;height:0;bottom:-8px;left:50%;transform:translate(-50%);border-left:8px solid transparent;border-right:8px solid transparent;border-top:8px solid rgba(0,0,0,.7)}", "",{"version":3,"sources":["webpack://./src/components/Tooltip/Tooltip.scss"],"names":[],"mappings":"AAAA,uBACE,iBAAA,CAAkB,oBAAA,CACG,kBAAA,CACF,YAAA,CACN,+BAAA,CACmB,iBAAA,CACd,cACH,CAPjB,8CAUI,iBAAA,CAAkB,OAAA,CACV,QAAA,CACC,WAAA,CACG,QAAA,CACH,yBAAA,CACoB,iCAAA,CACK,kCAAA,CACC,mCACC","sourcesContent":[".tooltip {\n  position: absolute;\n  display: inline-block;\n  white-space: nowrap;\n  padding: .5em;\n  background-color: rgba(0,0,0,.7);\n  border-radius: 4px;\n  font-size: 12px;\n\n  .arrow {\n    position: absolute;\n    width: 0;\n    height: 0;\n    bottom: -8px;\n    left: 50%;\n    transform: translate(-50%, 0);\n    border-left: 8px solid transparent;\n    border-right: 8px solid transparent;\n    border-top: 8px solid rgba(0,0,0,.7);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "ZvXK93SsvxyIb233FSywy",
	"arrow": "beEjLFtCH_zRVDXayXNv0"
};
export default ___CSS_LOADER_EXPORT___;
