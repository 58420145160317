import React from 'react';
import classes from './Header.scss';
import Logo from '../../components/Logo';
import TopNav from '../../components/TopNav';
import Divider from '../../components/Divider';

const Header = ({ children }) => (
  <div className={`${classes['header']} cont`}>
    <div className="mid-cont">
      <Logo />
      <TopNav>
        {children}
      </TopNav>
    </div>
    <Divider />
  </div>
);

Header.propTypes = {
  children: React.PropTypes.node
};

export default Header;
