// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2YRBtL3Mw48lWp5c_DZQ2J{position:absolute;left:0;top:0;width:100%;height:100%;background-color:rgba(0,0,0,.6);display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;z-index:999}._2YRBtL3Mw48lWp5c_DZQ2J ._2X_fHjicIYCZLkFvqnmnvD{border:16px solid #f3f3f3;border-top:16px solid #3498db;border-radius:50%;width:120px;height:120px;animation:LIU5vNQsAXRqf_KFHrVMy 2s linear infinite}@keyframes LIU5vNQsAXRqf_KFHrVMy{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", "",{"version":3,"sources":["webpack://./src/components/Spinner/Spinner.scss"],"names":[],"mappings":"AAAA,yBACE,iBAAA,CAAkB,MAAA,CACX,KAAA,CACD,UAAA,CACK,WAAA,CACC,+BAAA,CACoB,mBAAA,CAChC,YAAA,CAAa,oBAAA,CACb,sBAAA,CAAuB,qBAAA,CACvB,kBAAA,CAAmB,WACP,CAVd,kDAaI,yBAAA,CAA0B,6BAAA,CACI,iBAAA,CACZ,WAAA,CACN,YAAA,CACC,kDACqB,CACnC,iCAID,GAAK,sBAAuB,CAAA,GACrB,uBAAyB,CAAA","sourcesContent":[".spinner-wrapper {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0,0,0,.6);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 999;\n\n  .spinner {\n    border: 16px solid #f3f3f3; /* Light grey */\n    border-top: 16px solid #3498db; /* Blue */\n    border-radius: 50%;\n    width: 120px;\n    height: 120px;\n    animation: spin 2s linear infinite;\n  }\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner-wrapper": "_2YRBtL3Mw48lWp5c_DZQ2J",
	"spinner": "_2X_fHjicIYCZLkFvqnmnvD",
	"spin": "LIU5vNQsAXRqf_KFHrVMy"
};
export default ___CSS_LOADER_EXPORT___;
