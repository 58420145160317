// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._31_qXriUzI5NXxma9g5hRD{-ms-flex-direction:row;flex-direction:row;background-color:#2a2f35;-ms-flex-pack:center;min-height:-webkit-min-content;min-height:min-content}._31_qXriUzI5NXxma9g5hRD,._31_qXriUzI5NXxma9g5hRD ul{display:-ms-flexbox;display:flex;justify-content:center}._31_qXriUzI5NXxma9g5hRD ul{margin:0;-ms-flex:1 0 auto;flex:1 0 auto;-ms-flex-pack:center}._31_qXriUzI5NXxma9g5hRD ul li{float:left;display:-ms-flexbox;display:flex;padding:.5em}._31_qXriUzI5NXxma9g5hRD ul li a{padding:.5em 2em;color:#fff;border-radius:4px;cursor:pointer}._31_qXriUzI5NXxma9g5hRD ul li a:hover{background-color:rgba(0,0,0,.2)}", "",{"version":3,"sources":["webpack://./src/routes/Admin/containers/MovieEditor/Control/Control.scss"],"names":[],"mappings":"AAAA,yBAEE,sBAAA,CAAA,kBAAA,CAAmB,wBAAA,CACM,oBAAA,CACF,8BAAA,CACvB,sBAAuB,CALzB,qDACE,mBAAA,CAAA,YAAA,CAAa,sBAGU,CAJzB,4BAQI,QAAA,CAAS,iBAAA,CAET,aAAA,CAAc,oBACS,CAX3B,+BAcM,UAAA,CAAW,mBAAA,CACX,YAAA,CAAa,YACA,CAhBnB,iCAmBQ,gBAAA,CAAiB,UAAA,CACN,iBAAA,CACO,cACH,CAtBvB,uCA0BQ,+BAAgC","sourcesContent":[".control {\n  display: flex;\n  flex-direction: row;\n  background-color: #2A2F35;\n  justify-content: center;\n  min-height: min-content;\n\n  ul {\n    margin: 0;\n    display: flex;\n    flex: 1 0 auto;\n    justify-content: center;\n\n    li {\n      float: left;\n      display: flex;\n      padding: .5em;\n\n      a {\n        padding: .5em 2em;\n        color: #fff;\n        border-radius: 4px;\n        cursor: pointer;\n      }\n\n      a:hover {\n        background-color: rgba(0,0,0,.2);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": "_31_qXriUzI5NXxma9g5hRD"
};
export default ___CSS_LOADER_EXPORT___;
