import React from 'react';
import { IndexLink, Link } from 'react-router';
import Header from '../../../components/Header';

export default () => (
  <Header>
    <IndexLink to="/">PRODUCTION</IndexLink>
    <Link to="/contact">CONTACT</Link>
  </Header>
);
