// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./delete.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2yIdsUpVv5pCjShwkGtpEC{cursor:pointer;color:#ddd;padding:.2em 1em;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._2yIdsUpVv5pCjShwkGtpEC:before{content:\" \";width:16px;height:16px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;background-position:50% 50%}._2yIdsUpVv5pCjShwkGtpEC:hover{background-color:rgba(0,0,0,.2)}", "",{"version":3,"sources":["webpack://./src/components/DeleteButton/DeleteButton.scss"],"names":[],"mappings":"AAAA,yBACE,cAAA,CAAe,UAAA,CACJ,gBAAA,CACM,mBAAA,CACjB,YAAA,CAAa,qBAAA,CACb,kBAAmB,CACpB,gCAGC,WAAA,CAAY,UAAA,CACD,WAAA,CACC,wDAAA,CACuC,2BAAA,CACvB,uBAAA,CACJ,2BACI,CAC7B,+BAGC,+BAAgC","sourcesContent":[".delete-button {\n  cursor: pointer;\n  color: #ddd;\n  padding: .2em 1em;\n  display: flex;\n  align-items: center;\n}\n\n.delete-button:before {\n  content: ' ';\n  width: 16px;\n  height: 16px;\n  background-image: url('../DeleteButton/delete.svg');\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: 50% 50%;\n}\n\n.delete-button:hover {\n  background-color: rgba(0,0,0,.2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delete-button": "_2yIdsUpVv5pCjShwkGtpEC"
};
export default ___CSS_LOADER_EXPORT___;
