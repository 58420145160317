import React from 'react';
import classes from './Contact.scss';
import GMap from '../../components/GMap';
import imageMika from '../Contact/mika.jpg';

const Contact = () => (
  <div className={`${classes['contact']} flex-item`}>
    <div className={`${classes['contact-info']} mid-cont flex-container dir-row flex-wrap`}>
      <div className="col flex-container flex-vtop dir-col nowrap pad-1-v">
        <h2>Silva Mysterium Oy</h2>
        <p>
          Pulttitie 16, 3. kerros<br />
          00880 Helsinki (Roihupelto)<br />
          Finland
        </p>
      </div>
      <div className="col flex-container flex-vtop dir-col nowrap pad-1-v">
        <div className={`${classes['map']} cont`}>
          <GMap />
        </div>
      </div>
    </div>
    <div className={`${classes['contact-people']} mid-cont flex-container dir-row flex-wrap`}>
      <div className="col flex-container dir-col flex-vtop nowrap pad-1-v">
        <div className="pad-1-v">
          <h2>Mika Ritalahti</h2>
          <p>Tuottaja / Producer</p>
        </div>
        <div>
          <p>
            <label>PHONE</label>
            <span>+358-40-707 4848</span>
          </p>
          <p>
            <label>EMAIL</label>
            <span><a href="mailto:mika.ritalahti@silvamysterium.fi">mika.ritalahti@silvamysterium.fi</a></span>
          </p>
        </div>
        <div className="pad-2-v">
          <img src={imageMika} width="200" style={{border: '1px solid #666'}} />
        </div>
      </div>
      <div className="col flex-container dir-col flex-vtop nowrap pad-1-v">
        <div className="pad-1-v">
          <h2>Niko Ritalahti</h2>
          <p>Tuottaja / Producer</p>
        </div>
        <div>
          <p>
            <label>PHONE</label>
            <span>+358-400-44 6555</span>
          </p>
          <p>
            <label>EMAIL</label>
            <span><a href="mailto:niko.ritalahti@silvamysterium.fi">niko.ritalahti@silvamysterium.fi</a></span>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Contact;
