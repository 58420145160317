import React from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select-plus';
import { push } from 'redux-router';
import { connect } from 'react-redux';
import * as productionService from '../../services/productionService';
import * as imageService from '../../services/imageService';
import * as categoryService from '../../services/categoryService';
import MovieCard from '../../components/MovieCard';
import MovieThumbnail from '../../components/MovieThumbnail';
import classes from './ProductionList.scss';

function defaultCardGenerator (production, images, lang, onClick = () => {}) {
  return production.map((movie, index) => {
    const isStock = movie.image.startsWith('static_');
    let image;
    if (isStock) {
      image = movie.image;
    } else {
      image = images[ movie.image ] ? images[ movie.image ].thumb : '';
    }

    return (
      <MovieCard movie={movie} lang={lang} key={index} onClick={onClick}>
        <MovieThumbnail image={image} stock={isStock} />
      </MovieCard>
    );
  });
}

class ProductionList extends React.Component {
  static propTypes = {
    cardGenerator: React.PropTypes.func.isRequired,
    lang: React.PropTypes.string,
    extra: React.PropTypes.array,
    children: React.PropTypes.node,
    dispatch: React.PropTypes.func.isRequired
  };

  static defaultProps = {
    cardGenerator: defaultCardGenerator,
    lang: 'eng',
    extra: []
  };

  state = {
    production: [],
    images: {},
    categories: [],
    filters: [],
    listWidth: 0
  };

  constructor (props) {
    super(props);
    this.handleMovieClick = this.handleMovieClick.bind(this);
    this.calcListWidth = this.calcListWidth.bind(this);
    this.handleChangeFilter = this.handleChangeFilter.bind(this);
  }

  componentDidMount () {
    window.addEventListener('resize', this.calcListWidth);

    this.fetchProduction();
    this.fetchImages();
    this.fetchCategories();

    this.calcListWidth();
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.calcListWidth);
  }

  componentDidUpdate () {
    this.calcListWidth();
  }

  calcListWidth () {
    const node = ReactDOM.findDOMNode(this.refs.list);
    const computedStyle = getComputedStyle(node);
    let elementWidth = node.clientWidth;
    elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

    if (this.state.listWidth !== elementWidth) {
      this.setState({ listWidth: elementWidth });
    }
  }

  async fetchProduction () {
    const production = await productionService.fetchProduction(this.props.lang);
    this.setState({ production: [ ...this.props.extra, ...production ] });
  }

  async fetchImages () {
    const images = await imageService.fetchImages();
    this.setState({ images });
  }

  async fetchCategories () {
    const categoryData = await categoryService.fetchCategories(this.props.lang, 'plural');
    const categories = Object.keys(categoryData).map(categoryId => ({
      value: categoryId,
      label: categoryData[ categoryId ]
    }));
    this.setState({ categories });
  }

  handleChangeFilter (filters) {
    this.setState({ filters: filters || [] });
  }

  isMovieVisible (movie) {
    return this.state.filters.length === 0 ||
      this.state.filters.filter(f => f.value === movie.categoryId).length > 0;
  }

  handleMovieClick (id) {
    this.props.dispatch(push(`/movies/${id}`));
  }

  render () {
    const { cardGenerator, children } = this.props;
    const fits = this.state.listWidth / 380 > this.state.categories.length;
    const numColumns = Math.max(1, Math.floor(this.state.listWidth / 340));
    const production = cardGenerator(
      this.state.production.filter(movie => this.isMovieVisible(movie)),
      this.state.images,
      this.props.lang,
      this.handleMovieClick
    );

    const columns = production.reduce((pre, elem, index) => {
      if (!pre[index % numColumns]) {
        pre[index % numColumns] = [];
      }

      pre[index % numColumns].push(elem);
      return pre;
    }, new Array(numColumns));

    // add dummy cards to keep layout neat
    for (let i = production.length; i < numColumns; i++) {
      columns.push(<div className="flex-item"></div>);
    }

    return (
      <div className={`${classes['production-list']} ${fits ? 'fits' : ''} mid-cont`} ref="list">
        {children}
        <div className={classes['filter-div']}>
          <ul>
            <li>
              <FilterButton
                onClick={this.handleChangeFilter}
                active={this.state.filters.length === 0}
                filters={[]}
              >
                All
              </FilterButton>
            </li>
            {this.state.categories.map(category => {
              const fIndex = this.state.filters.map(f => f.value).indexOf(category.value);
              let newFilters = this.state.filters.slice(0);
              if (fIndex > -1) {
                newFilters.splice(fIndex, 1);
              } else {
                newFilters.push(Object.assign({}, category));
              }

              return (
                <li key={category.value}>
                  <FilterButton onClick={this.handleChangeFilter} active={fIndex > -1} filters={newFilters}>
                    {category.label}
                  </FilterButton>
                </li>
              );
            })}
          </ul>
          <Select
            options={this.state.categories}
            value={this.state.filters}
            multi
            onChange={this.handleChangeFilter}
            placeholder="Select filter..."
            backspaceToRemoveMessage="" />
        </div>
        <div className={`${classes['content']} cont flex-container ${numColumns === 1 && 'flex-hcenter'}`}>
          {columns.map((col, index) => {
            return (
              <div className={`${classes['col']} flex-container dir-col`} key={index}>
                {col}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect()(ProductionList);

class FilterButton extends React.Component {
  static propTypes = {
    onClick: React.PropTypes.func.isRequired,
    filters: React.PropTypes.array.isRequired,
    active: React.PropTypes.bool.isRequired,
    children: React.PropTypes.node
  };

  constructor (props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick () {
    this.props.onClick(this.props.filters);
  }

  render () {
    return (
      <a onClick={this.handleClick} className={this.props.active ? classes['active'] : ''}>
        {this.props.children}
      </a>
    );
  }
}
