import React from 'react';
import ReactDOM from 'react-dom';

export default (InnerComponent) => class extends InnerComponent {
  state = { width: 0, height: 0, props: {}, attrs: [] };

  constructor (props) {
    super(props);
    this.checkResize = this.checkResize.bind(this);
  }

  componentDidMount () {
    this.parseAttributes(ReactDOM.findDOMNode(this._c));
    window.addEventListener('resize', this.checkResize, true);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.checkResize, true);
  }

  checkResize () {
    let node = ReactDOM.findDOMNode(this._c);
    let rect = this.getElementRect(node);
    if (this.state.width !== rect.width || this.state.height !== rect.height) {
      let props = {};

      this.state.attrs.forEach(attr => {
        const { mod, dir, size } = attr;
        const key = `data-${mod}-${dir}`;
        if (!props[ key ]) {
          props[ key ] = '';
        }

        if ((mod === 'min' && rect[ dir ] >= size) || (mod === 'max' && rect[ dir ] <= size)) {
          props[ key ] += `${size}px `;
        }
      });

      this.setState({
        width: rect.width,
        height: rect.height,
        props
      });
    }
  }

  getElementRect (element) {
    var computedStyle = getComputedStyle(element);

    let elementHeight = element.clientHeight;  // height with padding
    let elementWidth = element.clientWidth;   // width with padding

    elementHeight -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
    elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

    return {
      width: elementWidth,
      height: elementHeight
    };
  }

  parseAttributes (elem) {
    const regex = new RegExp(`${elem.className}((?:\[[\s\t]*?data-(?:min|max)-(?:width|height)[\s\t]*?[~$\^]?=[\s\t]*?"[^"]*?"[\s\t]*?])+)([^,\n\s\{]*)`, 'mgi');
    const attrRegex = new RegExp(`\[[\s\t]*?data-(min|max)-(width|height)[\s\t]*?[~$\^]?=[\s\t]*?"([^"]*?)"[\s\t]*?]`, 'mgi');
    let match, attrMatch, attrs = [];

    for (let i = 0; i < document.styleSheets.length; i++) {
      try {
        let rules = document.styleSheets[ i ].cssRules;
        for (let j = 0; j < rules.length; j++) {
          /* match selector string */
          while ((match = regex.exec(rules[ j ].selectorText)) !== null) {
            /* match attributes from matched selector */
            while ((attrMatch = attrRegex.exec(match[ 1 ])) !== null) {
              attrs.push({
                mod: attrMatch[ 1 ],
                dir: attrMatch[ 2 ],
                size: parseInt(attrMatch[ 3 ])
              });
            }
          }
        }
      } catch (err) {}
    }

    this.setState({ attrs });
  }

  render () {
    const element = super.render();
    return React.cloneElement(element, {
      ...this.state.props,
      ref: c => {
        if (c) this.checkResize(this._c = c);
      }
    });
  }
};
