import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { push } from 'redux-router';
import constants from '../constants';
const { LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_FAILURE, LOGOUT_USER } = constants;

export function loginUserSuccess (token) {
  localStorage.setItem('token', token);
  return {
    type: LOGIN_USER_SUCCESS,
    payload: { token }
  };
}

export function loginUserFailure () {
  localStorage.removeItem('token');
  return {
    type: LOGIN_USER_FAILURE
  };
}

export function loginUserRequest () {
  return {
    type: LOGIN_USER_REQUEST
  };
}

export function logout () {
  localStorage.removeItem('token');
  return {
    type: LOGOUT_USER
  };
}

export function logoutAndRedirect () {
  return (dispatch, state) => {
    dispatch(logout());
    dispatch(push('/login'));
  };
}

export function loginUser (fd, redirect = '/admin') {
  return dispatch => {
    dispatch(loginUserRequest());
    return axios.post('/api/auth/token/', fd).then(response => {
      try {
        const token = response.data.token;
        jwtDecode(token);
        dispatch(loginUserSuccess(token));
        dispatch(push(redirect));
      } catch (e) {
        dispatch(loginUserFailure({
          response: {
            status: 403,
            statusText: 'Invalid token'
          }
        }));
      }
    }).catch(error => {
      dispatch(loginUserFailure(error));
    });
  };
}

export function authenticateToken (token) {
  return dispatch => {
    dispatch(loginUserRequest());
    return axios.get('/api/auth/authenticate', {
      headers: { 'Authorization': `Bearer ${token}` }
    }).then(response => {
      dispatch(loginUserSuccess(token));
    }).catch(error => {
      dispatch(loginUserFailure(error));
    });
  };
}
