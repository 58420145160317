// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./bg2.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".v8hKZzGjkyk_0sF9gdCx3{background-color:#2a2f35;min-width:320px!important;min-height:170px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% initial;background-position:0 0;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:justify;justify-content:space-between}.v8hKZzGjkyk_0sF9gdCx3 a{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss"],"names":[],"mappings":"AAAA,uBACE,wBAAA,CAA+B,yBAAA,CACJ,gBAAA,CACV,kDAAA,CACmB,4BAAA,CACP,uBAAA,CACL,mBAAA,CACxB,YAAA,CAAa,yBAAA,CACb,qBAAA,CAAsB,qBAAA,CACtB,6BAA8B,CAThC,yBAYI,cAAe","sourcesContent":[".header {\n  background-color: rgb(42,47,53);\n  min-width: 320px !important;\n  min-height: 170px;\n  background: url('../Header/bg2.jpg');\n  background-size: 100% initial;\n  background-position: 0 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  a {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "v8hKZzGjkyk_0sF9gdCx3"
};
export default ___CSS_LOADER_EXPORT___;
