import React from 'react';
import classes from './Spinner.scss';

const Spinner = ({ active }) => (
  <div className={classes['spinner-wrapper']} style={active ? {} : {display: 'none'}}>
    <div className={classes['spinner']}></div>
  </div>
);

Spinner.propTypes = {
  active: React.PropTypes.bool.isRequired
};

export default Spinner;
