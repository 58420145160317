// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._28TLzjrtPeNwK6-EXlLCd0{background-color:#222;padding:26px 0;color:#fff;letter-spacing:1px}._28TLzjrtPeNwK6-EXlLCd0 h2{font-size:16px;margin:1em 0}._28TLzjrtPeNwK6-EXlLCd0 p{font-size:13px;line-height:1.3em}._28TLzjrtPeNwK6-EXlLCd0 a{color:#fff}._28TLzjrtPeNwK6-EXlLCd0 a:hover{color:#aaa}", "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA,yBACE,qBAAA,CAAsB,cAAA,CACP,UAAA,CACJ,kBACQ,CAJrB,4BAOI,cAAA,CAAe,YACF,CARjB,2BAYI,cAAA,CAAe,iBACG,CAbtB,2BAiBI,UAAW,CAjBf,iCAqBI,UAAW","sourcesContent":[".footer {\n  background-color: #222;\n  padding: 26px 0;\n  color: #fff;\n  letter-spacing: 1px;\n\n  h2 {\n    font-size: 16px;\n    margin: 1em 0;\n  }\n\n  p {\n    font-size: 13px;\n    line-height: 1.3em;\n  }\n\n  a {\n    color: #fff;\n  }\n\n  a:hover {\n    color: #aaa;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "_28TLzjrtPeNwK6-EXlLCd0"
};
export default ___CSS_LOADER_EXPORT___;
