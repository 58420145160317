import React from 'react';
import { Route, IndexRoute } from 'react-router';
import AdminApp from './containers/AdminApp';
import AdminProductionList from './containers/AdminProductionList';
import MovieEditor from './containers/MovieEditor';
import { requireAuthentication } from '../../containers/AuthenticatedApp';

export default (
  <Route component={requireAuthentication(AdminApp)}>
    <IndexRoute component={AdminProductionList} />
    <Route path="movies/:movieKey" component={MovieEditor} />
  </Route>
);
