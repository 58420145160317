import api from '../api';

export async function fetchImages () {
  let images = (await api.getImages()).data;
  return images;
}

export async function fetchImage (key) {
  if (key.startsWith('static_')) {
    return { full: '', thumb: key };
  }

  let images = (await api.getImages()).data;
  return images[ key ] || { full: '', thumb: '' };
}

export async function uploadImage (token, fd, onProgress) {
  let response = (await api.uploadImage(token, fd, onProgress)).data;
  return response;
}

export async function deleteImage (token, id) {
  let fd = new FormData();
  fd.append('id', id);
  let response = (await api.deleteImage(token, fd)).data;
  return response;
}
